import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
// import "./Mint.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import styles from "./Mint.module.css";
import { useNavigate, useParams } from "react-router-dom";
import BackgroundWrapper from "../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { toast } from "react-hot-toast";
import { axiosRequestAuth } from "../../../../services/api/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomInput from "../../../shared/customInput/customInput";

import CustomLoader from "../../../shared/customLoader/customLoader";
import { getCollectionById } from "../../../../services/redux/NftApiReducer/NftApiReducer";

const CollectionForm = () => {
  const [loading, setLoading] = useState(false);
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isUSD, setIsUSD] = useState("B");
  const params = useParams();
  const [formDataImage, setFormDataImage] = useState(null); // setState for selected image

  const [selectedImage, setSelectedImage] = useState(null); // setState for selected image
  const [showImageError, setShowImageError] = useState(false); // setState for selected image
  const [vanityURL, setVanityURL] = useState("");
  // testing ////////////////////////

  const [firstPart, setFirstPart] = useState(
    `/collection/${localStorage.getItem("accnum")}/`
  ); // Change 'FixedText' to your desired initial value

  const handleImageClick = () => {
    // Programmatically trigger the file input when the box is clicked
    document.getElementById("image-upload-input").click();
  };

  const schema = yup
    .object({
      collectionName: yup.string().required("Collection Name is required"),
      category: yup
        .number()
        .typeError("Category is required")
        .required("Category is required"),
      vanityURL: yup
        .string()
        .transform((value) => (value ? value.replace(/\s+/g, "-") : value)) // Replace spaces with "-"
        .required("URL is required")
        .test(
          "no-slash",
          'URL cannot contain "/"',
          (value) => !value.includes("/")
        ),

      description: yup.string("Description is required"),

      collectionType: yup
        .string()
        .required("Collection type is required")
        .default("B"),
      bucket: yup
        .string()
        .test(
          "conditional-validation",
          "S3 link is required for USD collection",
          function (value) {
            const collectionType = this.parent.collectionType;
            if (collectionType === "B") {
              if (!value) {
                throw this.createError({
                  message: "S3 link is required for USD collection",
                  path: this.path,
                });
              }
            }
            return true; // Return true to indicate validation passed
          }
        ),
      issuer: yup
        .string()
        .min(32)
        .test(
          "conditional-validation",
          "Issuer is required for XRP collection",
          function (value) {
            const collectionType = this.parent.collectionType;
            if (collectionType === "A") {
              if (!value) {
                throw this.createError({
                  message: "Issuer is required for XRP collection",
                  path: this.path,
                });
              }
            }
            return true; // Return true to indicate validation passed
          }
        ),
      taxonId: yup
        .string()
        .test(
          "conditional-validation",
          "Taxon is required for XRP collection",
          function (value) {
            const collectionType = this.parent.collectionType;
            if (collectionType === "A") {
              if (!value) {
                throw this.createError({
                  message: "Taxon is required for XRP collection",
                  path: this.path,
                });
              }
            }
            return true; // Return true to indicate validation passed
          }
        ),

      // price: yup
      //   .number()
      //   .test(
      //     "conditional-validation",
      //     "Price is required for USD collection",
      //     function (value) {
      //       const collectionType = this.parent.collectionType;
      //       if (collectionType === "B") {
      //         if (!value) {
      //           throw this.createError({
      //             message: "Price is required for USD collection",
      //             path: this.path,
      //           });
      //         }
      //       }
      //       return true; // Return true to indicate validation passed
      //     }
      //   ),
      price: yup
        .number() // Ensures the price is a number
        .typeError("Price should be a number") // Error message for non-numeric values
        .test(
          "conditional-validation",
          "Price is required for USD collection",
          function (value) {
            const { collectionType } = this.parent;

            // Handle the required logic within the custom test
            if (collectionType === "B") {
              if (
                value === undefined ||
                value === null ||
                isNaN(value) ||
                value === ""
              ) {
                return this.createError({
                  message: "Price is required for USD collection",
                  path: this.path,
                });
              }
            }
            return true; // Return true to indicate validation passed
          }
        )
        .required("Price is required"), // Handle required for non-USD collections
      royalty: yup
        .number() // Ensures the input is a number
        .typeError("Royality must be a number")
        .test(
          "conditional-validation",
          "Royality is required for USD collection ",
          function (value) {
            const collectionType = this.parent.collectionType;
            if (collectionType === "B") {
              if (!value) {
                throw this.createError({
                  message: "Royality is required for USD collection",
                  path: this.path,
                });
              }
            }
            return true; // Return true to indicate validation passed
          }
        ),

      collectionMediaType: yup.string().required("Media type is required"),
      flag: yup.string().required("Must select 1 option"),

      // royality: yup.number(),
      // .required("Royalty is required")
      // .typeError("Royalty must be a number"),
    })
    .required();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormDataImage(file);

      // You can perform additional checks here, e.g., file type, size, etc.

      // Read the selected file as a data URL and set it as the selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  let gamifiedOptions = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
  ];

  let collectionMediaTypeOptions = [
    { id: "image", name: "Image" },
    { id: "audio", name: "Audio" },
    { id: "video", name: "Video" },
  ];
  let flags = [
    { id: 8, name: "Transferable" },
    { id: 9, name: "Transferable, Burnable" },
  ];

  let collectionTypeOptions = [
    { id: "A", name: "XRP" },
    { id: "B", name: "USD" },
    { id: "G", name: "GAME" },
  ];
  const onSubmit = (data) => {
    if (data?.collectionType === "B") {
      if (!isNumber(data.price)) {
        toast.error("Price need to be a number");
        return;
      }
      if (!isNumber(data.royalty)) {
        toast.error("Royalty need to be a number");
        return;
      }
      if (data.taxonId) {
        if (!isNumber(data.taxonId)) {
          toast.error("Taxon need to be a number");
          return;
        }
      }
    } else if (data?.collectionType === "A") {
      if (!isNumber(data.taxonId)) {
        toast.error("Taxon need to be a number");
        return;
      }
    }

    if (!selectedImage) {
      toast.error("Please select image");
    }

    let formData = new FormData();
    formData.append("name", data?.collectionName);
    formData.append("category_id", data?.category);
    formData.append("description", data?.description);
    formData.append("thumbnail", formDataImage);
    formData.append("collection_media_type", data?.collectionMediaType);
    formData.append("collection_type", data?.collectionType);
    formData.append("vanity_collection_name", vanityURL);
    formData.append("flag", parseInt(data?.flag));
    formData.append("taxon_id", data?.taxonId);
    formData.append("s3_bucket_link", data?.bucket);
    formData.append("price", data?.price);
    formData.append("royalty", data?.royalty);
    formData.append("issuer", data?.issuer);

    if (selectedImage) createCollection(formData);
  };
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getCollectionById(params);
  }, [params.id]);

  function isNumber(data) {
    return !isNaN(parseFloat(data)) && isFinite(data);
  }

  async function getCategories() {
    try {
      const response = await axiosRequestAuth("get", "category");
      // setLoading(false);

      // toast.success(response.data.message);
      setCategories(response?.data?.data?.categories);
    } catch (error) {
      setLoading(false);
    }
  }

  async function createCollection(formData) {
    setLoading(true);

    try {
      const response = await axiosRequestAuth(
        "post",
        "v2/collection",
        formData
      );
      toast.success(response?.data?.message);
      navigate(-1);
      reset();
      setFormDataImage();
      setSelectedImage();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }

  const checkIfUSD = (price) => {
    setIsUSD(price);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const updatedValue = value.replace(/\s+/g, "-");
    setVanityURL(updatedValue);
  };

  return (
    <BackgroundWrapper>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80vh", // Set minimum height to fill the entire viewport
            }}
          >
            <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item lg={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    pt: 1,
                    ml: 1,
                  }}
                >
                  <Button
                    size="large"
                    sx={{ color: txtcolor, mt: 2 }}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{ color: txtcolor, fontWeight: 600, mt: 2 }}
                  >
                    Create Collection
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} sm={12} sx={{ p: 1 }}>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  sx={{
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "center",
                      pt: -2,
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        mt: 4,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        {/* Hidden file input */}
                        <input
                          type="file"
                          accept="image/*"
                          {...register("image")}
                          error={!!errors.image}
                          helperText={errors.image?.message}
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="image-upload-input"
                        />

                        {/* Clickable box with image icon */}
                        <div
                          onClick={handleImageClick}
                          style={{
                            maxWidth: "100%",
                            minWidth: "250px",
                            height: "240px",
                            // border: "2px dashed purple",
                            borderRadius: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            background: bgcolor,
                            border: `2px solid ${
                              !selectedImage && showImageError
                                ? "red"
                                : "transparent"
                            }`,
                          }}
                        >
                          {selectedImage ? (
                            <img
                              src={selectedImage}
                              alt="Selected"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <CloudUploadIcon style={{ fontSize: 48 }} />
                          )}
                        </div>
                        {!selectedImage && showImageError && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            Image is required
                          </p>
                        )}
                      </div>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "800px",
                      }}
                    >
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ width: "100%" }}
                      >
                        {/* Place the form inside a grid item */}
                        <Grid container spacing={2} sx={{ p: 1 }}>
                          <Grid item xs={6}>
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Collection Name*
                              </Typography>

                              <CustomInput
                                name="collectionName"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ color: txtcolor }}
                            >
                              Set Flag
                            </Typography>
                            <CustomInput
                              name="flag"
                              register={register}
                              errors={errors}
                              bgcolor={bgcolor}
                              txtcolor={txtcolor}
                              isFullWidth={true}
                              options={flags}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ p: 1 }}>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ color: txtcolor }}
                            >
                              Gamified or not?
                            </Typography>
                            <CustomInput
                              name="gamified"
                              register={register}
                              errors={errors}
                              bgcolor={bgcolor}
                              txtcolor={txtcolor}
                              isFullWidth={true}
                              options={gamifiedOptions}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ color: txtcolor }}
                            >
                              {isUSD === "B" ? "Taxon ID" : "Taxon ID*"}
                            </Typography>
                            <CustomInput
                              name="taxonId"
                              register={register}
                              errors={errors}
                              bgcolor={bgcolor}
                              txtcolor={txtcolor}
                              isFullWidth={true}
                            />
                          </Grid>
                        </Grid>
                        <Box sx={{ p: 1 }}>
                          <Typography variant="body1" sx={{ color: txtcolor }}>
                            Vanity URL*
                          </Typography>

                          <Grid container spacing={0}>
                            <Grid item xs={8}>
                              <TextField
                                size="small"
                                // variant="outlined"
                                value={firstPart}
                                // disabled
                                fullWidth
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: "grey", // Background color when not active
                                    "&:hover fieldset": {
                                      borderColor: "purple", // Border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "none", // Border color on focus
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: txtcolor, // Text color
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                size="small"
                                {...register("vanityURL")}
                                value={vanityURL}
                                onChange={handleInputChange}
                                error={!!errors.vanityURL}
                                helperText={errors.vanityURL?.message}
                                fullWidth
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: bgcolor, // Background color when not active
                                    "&:hover fieldset": {
                                      borderColor: "purple", // Border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "purple", // Border color on focus
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: txtcolor, // Text color
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "white", // Color of the dropdown icon
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Grid
                          container
                          spacing={2}
                          display={"flex"}
                          direction={"row"}
                          p={1}
                        >
                          {/* <Grid item xs={3}> */}
                          <Grid
                            item
                            xs={isUSD === "B" || isUSD === "G" ? 3 : 6}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: txtcolor }}
                            >
                              Collection Type
                            </Typography>
                            <CustomInput
                              name="collectionType"
                              register={register}
                              errors={errors}
                              bgcolor={bgcolor}
                              txtcolor={txtcolor}
                              isFullWidth={true}
                              defaultValue="B"
                              options={collectionTypeOptions}
                              onChange={checkIfUSD}
                            />
                          </Grid>
                          {isUSD === "B" || isUSD === "G" ? (
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Price $
                              </Typography>
                              <CustomInput
                                name="price"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                          ) : (
                            ""
                          )}
                          {isUSD === "B" || isUSD === "G" ? (
                            <>
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  sx={{ color: txtcolor }}
                                >
                                  Royality
                                </Typography>
                                <CustomInput
                                  name="royalty"
                                  register={register}
                                  errors={errors}
                                  bgcolor={bgcolor}
                                  txtcolor={txtcolor}
                                  isFullWidth={true}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  sx={{ color: txtcolor }}
                                >
                                  Collection Media Type*
                                </Typography>
                                <CustomInput
                                  name="collectionMediaType"
                                  register={register}
                                  errors={errors}
                                  bgcolor={bgcolor}
                                  txtcolor={txtcolor}
                                  isFullWidth={true}
                                  options={collectionMediaTypeOptions}
                                />
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Collection Media Type*
                              </Typography>
                              <CustomInput
                                name="collectionMediaType"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                                options={collectionMediaTypeOptions}
                              />
                            </Grid>
                          )}
                        </Grid>

                        {isUSD === "B" || isUSD === "G" ? (
                          <Grid
                            container
                            spacing={2}
                            display={"flex"}
                            direction={"row"}
                            p={1}
                          >
                            <Grid item xs={2}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Category*
                              </Typography>
                              <CustomInput
                                name="category"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                                options={categories}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                S3 Bucket URL
                              </Typography>
                              <CustomInput
                                name="bucket"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Issuer*
                              </Typography>
                              <CustomInput
                                name="issuer"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                            {/* Add more CustomInput components as needed */}
                          </Grid>
                        ) : (
                          <Grid
                            container
                            spacing={2}
                            display={"flex"}
                            direction={"row"}
                            p={1}
                          >
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Category*
                              </Typography>
                              <CustomInput
                                name="category"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                                options={categories}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: txtcolor }}
                              >
                                Issuer*
                              </Typography>
                              <CustomInput
                                name="issuer"
                                register={register}
                                errors={errors}
                                bgcolor={bgcolor}
                                txtcolor={txtcolor}
                                isFullWidth={true}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {/* <Box sx={{ p: 1 }}>
                          <Typography variant="body1" sx={{ color: txtcolor }}>
                            Issuer Wallet Address
                          </Typography>
                          <CustomInput
                            name="issuer"
                            register={register}
                            errors={errors}
                            bgcolor={bgcolor}
                            txtcolor={txtcolor}
                            isFullWidth={true}
                          />
                        </Box> */}
                        <Box sx={{ p: 1 }}>
                          <Typography variant="body1" sx={{ color: txtcolor }}>
                            Description
                          </Typography>
                          <CustomInput
                            name="description"
                            register={register}
                            errors={errors}
                            bgcolor={bgcolor}
                            txtcolor={txtcolor}
                            isFullWidth={true}
                            multiline={true}
                          />
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              background: "#EFA61C",
                              color: "white",

                              "&:hover": {
                                backgroundColor: "#b2790d",
                                color: "f1f1f1",
                                "& .MuiSvgIcon-root": {
                                  color: "black", // Change the icon color on hover
                                },
                              },
                              "&:active": {
                                backgroundColor: "#e3ab12",
                                color: "f1f1f1",
                                "& .MuiSvgIcon-root": {
                                  color: "black", // Change the icon color on click
                                },
                              },
                              "&:disabled": {
                                backgroundColor: "#e3e3e3",
                                cursor: "not-allowed",
                              },
                            }}
                            onClick={() => {
                              setShowImageError(true);
                            }}
                          >
                            Create
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                  {/* </Paper>{" "} */}
                </Grid>
                <Grid item lg={2} sm={0}></Grid>
              </Grid>{" "}
            </Grid>{" "}
          </>
        )}
      </Box>
    </BackgroundWrapper>
  );
};

export default CollectionForm;
