import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { toast } from "react-hot-toast";

import TabPanel from "../../utils/TabPanels/Tabpanels";
// import {} from "../../services/redux/NftApiReducer/NftApiReducer";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import NFTCard from "../../components/features/NFTCard/NFTCard";
import UserCollectionCard from "../../components/features/UserCollectionCard/UserCollectionCard";
import {
  resetSellState,
  getListedNft,
  getMyOffersNft,
  getNftWaitingMyApproval,
  resetTransferNftState,
  getUserCollection,
  syncStatusAPI,
  syncNFTAPI,
  resetapproveOfferNft,
  resetsellOfferCancelState,
  getTransferOffer,
  getLikedNfts,
  resetAcceptTransferRes,
  resetBuyDirectNftState,
} from "../../services/redux/MyNftApiReducer/MyNftApiReducer";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  setCollectionName,
  setTaxonId,
  setCollectionId,
  setTaxonIdToNull,
  setCollectionIdToNull,
} from "../../services/redux/CollectionReducer/CollectionReducer";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import {
  setApiTimer,
  setCountData,
  setCountNFT,
} from "../../services/redux/UserNFTCountReducer/UserNFTCountReducer";
import { axiosRequestAuth } from "../../services/api/api";
import { resetText } from "../../services/redux/TextReducer/TextReducer";
import TransferView from "../../components/features/TransferView/TransferView";
import { resetbuyNFTState } from "../../services/redux/NftApiReducer/NftApiReducer";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const tabStyle = {
  // /fontSize: "14px", // Adjust the font size as needed
  fontWeight: 500,
};
const CustomLabel = ({ text, count }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <span>{text}</span>
    <Box
      sx={{
        position: "relative",
        borderRadius: "4px",
        padding: "4px 8px",
        backgroundColor: "purple",
        color: "white",
      }}
    >
      {count}
    </Box>
  </Box>
);
const MyNFTs = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [myNFT, setMyNFT] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tabValue, setTabValue] = useState(location?.pathname);
  const [loading1, setLoading] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);

  let [marker, setMarker] = useState("");
  const { text } = useSelector((state) => state.text);
  const { userId, allCounts } = useSelector((state) => state.userNft);
  const {
    sell,
    loading,
    approveOfferNft,
    listedNft,
    myOffersNft,
    waitingApproval,
    approvRes,
    transferNft,
    sellNFT,
    collection,
    nftSync,
    syncStatus,
    sellOfferCancelSatate,
    acceptTransferRes,
    getTransferOffer: transferList,
    getLikedNfts: likedNftsList,
    getTransferNftState,
    buyNFTState,
    buyDirectNFTState,
  } = useSelector((state) => state.mynft);

  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const containerRef = useRef();

  const handleSync = async () => {
    setLoadingSync(true);
    await dispatch(syncNFTAPI());
    console.log(nftSync, "nftSync");
    if (!nftSync.loading) {
      dispatch(syncStatusAPI());
      dispatch(getUserCollection());
      setLoadingSync(false);
    }
  };

  // console.log(syncStatus?.data?.data.sync, "syncStatus");
  // setting NFT's data
  useEffect(() => {
    dispatch(setTaxonIdToNull());
    dispatch(setCollectionIdToNull());
  }, []);

  // Loading
  useEffect(() => {
    setLoading(loading || sellNFT?.loading || likedNftsList?.loading);
  }, [loading, sellNFT, likedNftsList]);

  // changing tabs with params
  useEffect(() => {
    // Extract the tab type from the URL parameter
    const typeParam = params.type;

    // Map the tab type to its index
    const typeToIndex = {
      myNFTs: 1,
      myListedNFTs: 2,
      offers: 3,
      approvals: 4,
      transfer: 5,
      favorite: 6,
    };

    // Set the tab value based on the URL parameter
    setTabValue(typeToIndex[typeParam] || 1);

    // Your existing code...
  }, [params.type]);

  useEffect(() => {
    if (syncStatus?.data?.data?.sync === true) {
      // toast.success(transferNft?.message);
      dispatch(getUserCollection());
    }
  }, [syncStatus?.data?.data?.sync]);

  // changing tabs on Click

  useEffect(() => {
    dispatch(syncStatusAPI());
  }, []);

  function getData() {
    if (tabValue === 1) {
      dispatch(getUserCollection());
      userNFTs();
    } else if (tabValue === 2) {
      dispatch(getListedNft());
      // GetListedNFTs();
    } else if (tabValue === 3) {
      dispatch(getMyOffersNft());

      // GetNFTsWithOffersIcreated();
    } else if (tabValue === 4) {
      dispatch(getNftWaitingMyApproval());
      // MyNftsWithOffers();
    } else if (tabValue === 5) {
      //dispatch(getNftWaitingMyApproval());
      // MyNftsWithOffers();
      dispatch(getTransferOffer());
    } else if (tabValue === 6) {
      const data = { user_id: userId?.toString() };

      dispatch(getLikedNfts(data));
    }
  }

  useEffect(() => {
    dispatch(resetText(""));
    getData();
  }, [tabValue]);

  useEffect(() => {
    getCountsData();
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setMarker("");
    // Define the route names corresponding to each tab
    const types = [
      "myNFTs",
      "myListedNFTs",
      "offers",
      "approvals",
      "transfer",
      "favorite",
    ];

    // Use the navigate function to change the route
    navigate(`/nfts/${types[newValue - 1]}`);
  };

  const handleData = (e) => {
    dispatch(setTaxonId(e.taxon_id));
    dispatch(setCollectionName(e.name));
    dispatch(setCollectionId(e.id));

    // setTimeout(() => {
    navigate("/nfts/collection-nfts");
    // }, 2000); // 2000 milliseconds = 2 seconds
  };

  async function userNFTs() {
    try {
      const response = await axiosRequestAuth("get", "v2/user/nfts-count", {});
      if (response) {
        dispatch(setApiTimer(response.data.data.synced));
        dispatch(setCountNFT(response.data.data.total_nfts));
      }
    } catch (error) {}
  }

  async function getCountsData() {
    try {
      const response = await axiosRequestAuth("get", "v2/user/all-counts", {});
      if (response) {
        dispatch(setCountData(response.data.data.total_nfts));
      }
    } catch (error) {}
  }

  // Showing toast
  useEffect(() => {
    if (sell?.data?.status === true) {
      toast.success(sell?.data?.message);
      getData();
      dispatch(resetSellState());
    } else if (sell?.data?.tatus === false) {
      toast.error(sell?.data?.message);
    }
  }, [sell?.data?.status]);

  useEffect(() => {
    if (transferNft?.data?.status === true) {
      toast.success(transferNft?.data?.message);
      getData();
      dispatch(resetTransferNftState());
    } else if (transferNft?.data?.status === false) {
      toast.error(transferNft?.data?.message);
      dispatch(resetTransferNftState());
    }
  }, [transferNft?.data?.status]);

  useEffect(() => {
    if (buyNFTState?.data?.status === true) {
      toast.success(buyNFTState?.data?.message);
      getData();
      dispatch(resetbuyNFTState());
    } else if (buyNFTState?.data?.status === false) {
      toast.error(buyNFTState?.data?.message);
      dispatch(resetbuyNFTState());
    }
  }, [buyNFTState?.data?.status]);

  useEffect(() => {
    if (buyDirectNFTState?.data?.status === true) {
      toast.success(buyDirectNFTState?.data?.message);
      getData();
      dispatch(resetBuyDirectNftState());
    } else if (buyDirectNFTState?.data?.status === false) {
      toast.error(buyDirectNFTState?.data?.message);
      dispatch(resetBuyDirectNftState());
    }
  }, [buyDirectNFTState?.data?.status]);

  useEffect(() => {
    if (approvRes?.data?.status === true) {
      toast.success(approvRes?.data?.message);
      getData();
      dispatch(resetTransferNftState());
    } else if (approvRes?.data?.status === false) {
      toast.error(approvRes?.data?.message);
      dispatch(resetTransferNftState());
    }
  }, [approvRes?.data?.status]);

  useEffect(() => {
    if (approveOfferNft?.data?.status === true) {
      toast.success(approveOfferNft?.data?.message);
      getData();
      dispatch(resetapproveOfferNft());
      dispatch(getNftWaitingMyApproval());
      dispatch(getListedNft());
    } else if (approveOfferNft?.data?.status === false) {
      toast.error(approveOfferNft?.data?.message);
      dispatch(resetapproveOfferNft());
    }
  }, [approveOfferNft?.data?.status]);

  useEffect(() => {
    if (sellOfferCancelSatate?.data?.status === true) {
      toast.success(sellOfferCancelSatate?.data?.message);
      getData();
      dispatch(resetsellOfferCancelState());
      dispatch(resetText(""));
    } else if (sellOfferCancelSatate?.data?.status === false) {
      toast.error(sellOfferCancelSatate?.data?.message);
      dispatch(resetsellOfferCancelState());
      dispatch(resetText(""));
    }
  }, [sellOfferCancelSatate?.data?.status]);

  useEffect(() => {
    if (sellNFT?.data?.status) {
      toast.success(sellNFT?.data?.message);
      getData();
      dispatch(resetSellState());
    } else if (sellNFT?.data?.status === false) {
      toast.error(sellNFT?.data?.message);
      dispatch(resetSellState());
    }
  }, [sellNFT?.data?.status]);

  useEffect(() => {
    if (acceptTransferRes?.data?.status) {
      toast.success(acceptTransferRes?.data?.message);
      getData();
      dispatch(resetAcceptTransferRes());
    } else if (acceptTransferRes?.data?.status === false) {
      toast.error(acceptTransferRes?.data?.message);
      dispatch(resetAcceptTransferRes());
    }
  }, [acceptTransferRes?.data?.status]);

  useEffect(() => {
    setLoader(transferList.loading || acceptTransferRes.loading);
  }, [transferList.loading, acceptTransferRes.loading]);

  const { text: loaderText } = useSelector((state) => state.text);

  function renderContent() {
    return (
      <>
        <Box
          sx={{
            pb: 4,
            display: "flex",
            justifyContent: { xs: "center", sm: "center", md: "space-between" },
            alignItems: { xs: "center", sm: "center", md: "" },
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              pl: { sm: 1, md: 2, lg: 4 },
            }}
          >
            <Tabs
              onChange={handleChange}
              value={tabValue}
              variant="scrollable"
              scrollButtons="auto"
              TabScrollButtonProps={{
                sx: { color: "white" },
              }}
              TabIndicatorProps={{
                sx: { display: "none" },
              }}
              sx={{
                width: {
                  xs: "90vw",
                  sm: "90vw",
                  md: "fit-content",
                  lg: "fit-content",
                },
                minHeight: "fit-content",
                ml: 2,
                mr: 2,
                my: 1,
                overflow: "visible",
                backgroundColor: "rgba(54, 69, 79, 0.3)",
                borderRadius: 2,

                "& button.Mui-selected": {
                  color: "white",
                  borderRadius: 2,
                  backgroundColor: "#af22c8",
                  transition: "background-color 0.3s ease-in-out", // Add smooth transition
                },
                "& button:not(.Mui-selected):hover": {
                  opacity: 0.8,
                  borderRadius: 2,
                  transition: "color 0.3s ease-in-out", // Add smooth transition
                },
              }}
            >
              <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  minWidth: "fit-content",
                  minHeight: "fit-content",
                  fontSize: { xs: 10, sm: 11, md: 13, lg: 14 },

                  p: 2, // smaller padding on extra small screens
                }}
                label={
                  <CustomLabel
                    text="My NFT's"
                    count={allCounts?.total_nfts || 0}
                  />
                }
                value={1}
                style={tabStyle}
              />
              <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  minWidth: "fit-content",
                  minHeight: "fit-content",
                  fontSize: { xs: 10, sm: 11, md: 13, lg: 14 },

                  p: 2, // smaller padding on extra small screens
                }}
                label={
                  <CustomLabel
                    text="My Listed NFT's"
                    count={allCounts?.sellOffers || 0}
                  />
                }
                value={2}
                style={tabStyle}
              />

              <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  minWidth: "fit-content",
                  minHeight: "fit-content",
                  fontSize: { xs: 10, sm: 11, md: 13, lg: 14 },

                  p: 2, // smaller padding on extra small screens
                }}
                label={
                  <CustomLabel
                    text="My Offers"
                    count={allCounts?.mybuyOffers || 0}
                  />
                }
                value={3}
                style={tabStyle}
              />
              <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  minWidth: "fit-content",
                  minHeight: "fit-content",
                  fontSize: { xs: 10, sm: 11, md: 13, lg: 14 },

                  p: 2, // smaller padding on extra small screens
                }}
                label={
                  <CustomLabel
                    text="Waiting My Approval"
                    count={allCounts?.mybuyOffersOnMyNFT || 0}
                  />
                }
                value={4}
                style={tabStyle}
              />
              <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  minWidth: "fit-content",
                  minHeight: "fit-content",
                  fontSize: { xs: 10, sm: 11, md: 13, lg: 14 },

                  p: 2, // smaller padding on extra small screens
                }}
                label={
                  <CustomLabel
                    text="Transfer"
                    count={allCounts?.TransferOffers || 0}
                  />
                }
                value={5}
                style={tabStyle}
              />
              <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  minWidth: "fit-content",
                  minHeight: "fit-content",
                  fontSize: { xs: 10, sm: 11, md: 13, lg: 14 },

                  p: 2, // smaller padding on extra small screens
                }}
                label="favorite"
                value={6}
                style={tabStyle}
              />
            </Tabs>
          </Box>

          <Box
            sx={{
              mr: 2,
              pt: { xs: 2, sm: 2, md: 0 },
              width: "200px",
            }}
          >
            {syncStatus?.data?.data?.sync ? (
              <Tooltip
                title={
                  "If you don't see your recent NFTs, click the Sync NFTs button to refresh all data from the XRP blockchain. The syncing process takes time, so continue refreshing until you see your updated data."
                }
              >
                <Button
                  onClick={handleSync}
                  startIcon={<RefreshIcon />}
                  sx={{
                    background: "#0000",
                    borderRadius: 2,
                    border: "2px solid #BF40BF",
                    color: "white",
                    width: "100%",

                    "&:hover": {
                      // backgroundColor: "#330033 ",
                      boxShadow: "0 0 8px #BF40BF",
                      color: "white",
                      "& .MuiSvgIcon-root": {
                        color: "white", // Change the icon color on hover
                      },
                    },
                    "&:active": {
                      backgroundColor: "#4A235A",
                      color: "white",
                      "& .MuiSvgIcon-root": {
                        color: "white", // Change the icon color on click
                      },
                    },
                  }}
                >
                  sync nfts
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  "Your sync request is currently in progress. Please wait a moment; you'll be able to see the latest data shortly. Keep refreshing the page."
                }
              >
                <Button
                  size="small"
                  sx={{
                    background: "#0000",
                    borderRadius: 2,
                    border: "2px solid #BF40BF",
                    color: "white",
                    width: "100%",
                  }}
                >
                  {/* <customLoader width={"15%"} /> */}
                  syncing..
                  <CircularProgress
                    size="2rem"
                    sx={{ color: "#BF40BF", size: "12px", ml: 1 }}
                  />
                </Button>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Divider
          variant="middle"
          sx={{ mt: 1, backgroundColor: "#AF22C8", mx: 6 }}
        />

        <TabPanel value={tabValue} sx={{ color: "white" }} index={1}>
          {loading1 === true ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
            </Box>
          ) : collection == [] ||
            collection?.data?.user_collections.length === 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
              <Typography
                variant="h5"
                sx={{ color: "white", fontFamily: "goodTime" }}
              >
                No NFT's Found!{" "}
              </Typography>
            </Box>
          ) : (
            //////////////////////////// My NFTs ////////////////////////////

            <>
              <Grid
                container
                justifyContent={
                  collection?.data?.user_collections?.length < 4
                    ? "center"
                    : "flex-start"
                }
                spacing={2}
                sx={{ pt: 4, px: 4 }}
              >
                {collection?.data?.user_collections &&
                  collection?.data?.user_collections?.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      lg={3}
                      sm={6}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        py: 4,
                        // px: 4,
                        pb: 2,
                      }}
                    >
                      <UserCollectionCard data={item} onConfirm={handleData} />
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </TabPanel>
        <TabPanel value={tabValue} sx={{ color: "white" }} index={2}>
          {/* //////////////////////////// My Listed NFTs //////////////////////////// */}
          {loading1 === true ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader
                width={"60%"}
                text={text || text !== "" ? text : "Please wait ..."}
              />{" "}
            </Box>
          ) : (listedNft?.data?.user_nfts &&
              listedNft?.data?.user_nfts?.length === 0) ||
            !listedNft?.data?.user_nfts ? (
            // My listed NFTs

            <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
              <Typography
                variant="h5"
                sx={{ color: "white", fontFamily: "goodTime" }}
              >
                No NFT's Found!{" "}
              </Typography>
            </Box>
          ) : (
            <Grid
              container
              spacing={4}
              sx={{ pt: 4, pl: 2 }}
              justifyContent={
                listedNft?.data?.user_nfts?.length < 4 ? "center" : "flex-start"
              }
            >
              {listedNft?.data?.user_nfts &&
                listedNft?.data?.user_nfts?.map((items) => (
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    key={items.id}
                  >
                    <NFTCard nft={items} borderColor="#AF22C8" />
                  </Grid>
                ))}
            </Grid>
          )}
        </TabPanel>

        <TabPanel value={tabValue} sx={{ color: "white" }} index={3}>
          {loading1 === true ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
            </Box>
          ) : (myOffersNft?.data?.user_nfts &&
              myOffersNft?.data?.user_nfts.length === 0) ||
            !myOffersNft?.data?.user_nfts ? (
            <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
              <Typography
                variant="h5"
                sx={{ color: "white", fontFamily: "goodTime" }}
              >
                No NFT's Found!{" "}
              </Typography>
            </Box>
          ) : (
            <Grid
              container
              spacing={4}
              sx={{
                pt: 4,
                pl: 2,
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {myOffersNft?.data?.user_nfts?.map((items) => (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  key={items.id}
                >
                  <NFTCard nft={items} borderColor="#AF22C8" />
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>
        {/* ///////////////////// My offer */}
        <TabPanel value={tabValue} sx={{ color: "white" }} index={4}>
          {loading1 === true ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
            </Box>
          ) : (waitingApproval?.data?.user_nfts &&
              waitingApproval?.data?.user_nfts.length === 0) ||
            !waitingApproval?.data?.user_nfts ? (
            <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
              <Typography
                variant="h5"
                sx={{ color: "white", fontFamily: "goodTime" }}
              >
                No NFT's Found!{" "}
              </Typography>
            </Box>
          ) : (
            <Grid
              container
              spacing={4}
              sx={{ pt: 4, pl: 2 }}
              justifyContent={
                waitingApproval?.data?.user_nfts?.length < 4
                  ? "center"
                  : "flex-start"
              }
            >
              {waitingApproval?.data?.user_nfts?.map((items) => (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  key={items.id}
                >
                  <NFTCard nft={items} borderColor="#AF22C8" />
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>

        <TabPanel value={tabValue} sx={{ color: "white" }} index={5}>
          {loader === true ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader
                width={"60%"}
                text={
                  loaderText || loaderText !== ""
                    ? loaderText
                    : "Please wait ..."
                }
              />{" "}
            </Box>
          ) : (transferList?.data?.data?.nfts &&
              transferList?.data?.data?.nfts.length === 0) ||
            !transferList?.data?.data?.nfts ? (
            <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
              <Typography
                variant="h5"
                sx={{ color: "white", fontFamily: "goodTime" }}
              >
                No NFT's Found!{" "}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ pb: 4 }}>
              <TransferView data={transferList?.data?.data?.nfts} />
            </Box>
          )}
        </TabPanel>

        <TabPanel value={tabValue} sx={{ color: "white" }} index={6}>
          {loading1 === true ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh", // Set minimum height to fill the entire viewport
              }}
            >
              {" "}
              <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
            </Box>
          ) : (likedNftsList?.data?.data &&
              likedNftsList?.data?.data.length === 0) ||
            !likedNftsList?.data?.data ? (
            <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
              <Typography
                variant="h5"
                sx={{ color: "white", fontFamily: "goodTime" }}
              >
                No NFT's Found!{" "}
              </Typography>
            </Box>
          ) : (
            <Grid
              container
              spacing={4}
              sx={{ pt: 4, pl: 2 }}
              justifyContent={
                likedNftsList?.data?.data?.length < 4 ? "center" : "flex-start"
              }
            >
              {likedNftsList?.data?.data?.map((items) => (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  key={items.id}
                >
                  <NFTCard nft={items} borderColor="#AF22C8" />
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>
      </>
    );
  }
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <div style={{ bgcolor: "red" }}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={3}></Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={{ py: 1, pb: 2 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontFamily: "goodTime",
                    color: "white",
                    textAlign: "center",
                    py: 2,
                  }}
                >
                  {`My NFT's`}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} />
          </Grid>

          <Box ref={containerRef}>{renderContent()}</Box>
        </div>
      </div>
    </div>
  );
};
export default MyNFTs;

// Then, in your component's render method or JSX:
