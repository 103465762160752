import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Box,
  IconButton,
  TextField,
  InputAdornment,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CloseIcon from "@mui/icons-material/Close";
import SellIcon from "@mui/icons-material/PointOfSale"; // Replace with the appropriate icon
import TransferIcon from "@mui/icons-material/SwapHoriz"; // Replace with the appropriate icon
import CustomLoader from "../../shared/customLoader/customLoader";
import { useNavigate } from "react-router-dom";

function UsdModal({ openModal, onClose, imageUrl, nft, loading, noOfItems }) {
  const navigate = useNavigate();
  //const [timer, setTimer] = useState(40); // Initial timer value

  // useEffect(() => {
  //   let intervalId;

  //   // Start the countdown timer when the component mounts
  //   if (timer > 0) {
  //     intervalId = setInterval(() => {
  //       setTimer((prevTimer) => prevTimer - 1);
  //     }, 1000);
  //   }

  //   // Reset the timer when it reaches zero
  //   if (timer === 0) {
  //     setTimeout(() => {
  //       setTimer(0); // Reset timer after 2 seconds
  //     }, 2000);
  //   }

  //   // Cleanup function to clear the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, [timer]); // Run effect whenever 'timer' state changes

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            position: "fixed", // Or 'absolute' if you want it relative to its nearest positioned ancestor
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Optional: adds a white translucent background
            zIndex: 1000, // Ensure it's above all other content
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80vh", // Set minimum height to fill the entire viewport
            }}
          >
            <CustomLoader width={"60%"} text={"Waiting xaman approval ... "} />{" "}
          </Box>
        </Box>
      ) : (
        <Modal
          componentsProps={{
            backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
          }}
          open={openModal}
          onClose={onClose}
        >
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              direction: "column",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",

              flexDirection: "column",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                bgcolor: "black",
                border: "solid 2px #af22c8",
                borderRadius: 4,
                color: "white",
                p: { xs: 1, sm: 2, md: 4, lg: 4 },
                width: { xs: "90vw", sm: "70vw", md: "60vw", lg: "40vw" },
              }}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center", // Center items vertically
                  py: 1,
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ py: 0 }}
                >
                  <Box />

                  <Box
                    justifyContent={"end"}
                    sx={{
                      mt: { xs: -1, sm: -2, md: -4, lg: -4 },
                      mr: { xs: 1, sm: 0, md: -2, lg: -2 },
                    }}
                  >
                    <IconButton
                      sx={{
                        bgcolor: "none",
                        color: "white",
                        border: "2px solid #af22c8",
                        borderRadius: "8px", // Adjust the value to make it more square-ish
                        padding: "2px", // Adjust padding as needed
                      }}
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      border: "solid 2px #af22c8",
                      borderRadius: 4,
                      p: 2,
                      m: 1,
                    }}
                  >
                    {nft?.metadata?.video ? (
                      <video
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        autoPlay
                        loop
                        muted
                      >
                        <source src={nft?.metadata?.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={imageUrl}
                        alt="NFT"
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column" },
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "#af22c8",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        Task ID:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "white",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        123
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column" },
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "#af22c8",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        Task ID:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "white",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        123
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column" },
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "#af22c8",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        Task ID:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "white",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        123
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column" },
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "#af22c8",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        Task ID:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "white",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        123
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column" },
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "#af22c8",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        Task ID:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "white",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        123
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column" },
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "#af22c8",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        Task ID:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          color: "white",
                          textAlign: "start",
                          fontFamily: "goodTime",
                        }}
                      >
                        123
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<LocalOfferIcon />}
                    sx={{
                      backgroundColor: "#AF22C8",
                      fontFamily: "goodTime", // Same as above
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#AF22C8",
                      },
                      borderRadius: "20px",
                      textTransform: "none",
                    }}
                  >
                    Claim NFT
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              direction: "column",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",

              flexDirection: "column",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                bgcolor: "black",
                border: "solid 2px #af22c8",
                borderRadius: 4,
                color: "white",
                p: { xs: 1, sm: 2, md: 4, lg: 4 },
                width: { xs: "90vw", sm: "70vw", md: "60vw", lg: "40vw" },
                textAlign: "center",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ py: 1 }}
              >
                <Box />

                <Box
                  justifyContent={"end"}
                  sx={{
                    mt: { xs: -1, sm: -2, md: -4, lg: -4 },
                    mr: { xs: 1, sm: 0, md: -2, lg: -2 },
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: "none",
                      color: "white",
                      border: "2px solid #af22c8",
                      borderRadius: "8px", // Adjust the value to make it more square-ish
                      padding: "2px", // Adjust padding as needed
                    }}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <Typography sx={{ fontFamily: "goodTime", fontSize: 14, mb: 1 }}>
                🎉 New NFT Minting Request Generated! 🎉
              </Typography>
              <Typography
                sx={{
                  fontFamily: "goodTime",
                  fontSize: 9,
                  color: "#f1ed00",
                  pt: 1,
                }}
              >
                Once your {noOfItems > 1 ? "nfts are" : "nft is"} minted, you
                can claim your Newly minted {noOfItems > 1 ? "NFTs" : "NFT"} by
                going to the transfer section.
              </Typography>

              {/* {timer > 0 ? (
                <Typography sx={{ fontFamily: "goodTime", fontSize: 14 }}>
                  Don't refresh page
                </Typography>
              ) : (
                <Typography sx={{ fontFamily: "goodTime", fontSize: 14 }}>
                  Claim your NFT now:{" "}
                </Typography>
              )}
              {timer > 0 ? (
                <Typography
                  sx={{
                    fontFamily: "goodTime",
                    fontSize: 8,
                    color: "#f1ed00",
                    pt: 1,
                  }}
                >
                  You can claim your Newly minted NFT buy going to the transfer
                  section.
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontFamily: "goodTime",
                    fontSize: 8,
                    color: "#f1ed00",
                    pt: 1,
                  }}
                >
                  Transfer offer is being made please wait fot the timer to
                  expire
                </Typography>
              )} */}
              {/* {timer > 0 ? (
                <Typography
                  sx={{
                    fontFamily: "goodTime",
                    fontSize: 12,
                    p: 0.5,
                  }}
                >
                  {`Timer: ${timer}`}
                </Typography>
              ) : ( */}
              <Button
                sx={{
                  backgroundColor: "#AF22C8",

                  color: "white",
                  "&:hover": {
                    backgroundColor: "#AF22C8",
                  },
                  borderRadius: "20px",
                  textTransform: "none",
                  m: { xs: 1, sm: 2 },
                  px: 2,
                }}
                onClick={() => {
                  navigate("/nfts/transfer");
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "goodTime",
                  }}
                >
                  Go to Transfers
                </Typography>
              </Button>
              {/* <Button
                sx={{
                  backgroundColor: "#AF22C8",

                  color: "white",
                  "&:hover": {
                    backgroundColor: "#AF22C8",
                  },
                  borderRadius: "20px",
                  textTransform: "none",
                  m: { xs: 1, sm: 2 },
                  px: 2,
                }}
                onClick={() => {
                  onClose();
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "goodTime",
                  }}
                >
                  Cancel
                </Typography>
              </Button> */}
            </Paper>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default UsdModal;
